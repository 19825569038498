@tailwind base;
@tailwind components;
@tailwind utilities;

.body-dc {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  background-color: #fff;
  color: var(--cod-gray);
}

.header-dc {
  box-shadow: inset 0px -36px 16px -10px rgba(0, 0, 0, 0.1);
}

.header-dc-menu {
  box-shadow: 0px 0px 12px 0px #00000029;
}

.header-dc-menu-icon {
  box-shadow: 0px 0px 12px 0px #0000001f;
}

.header-dc-menu-icon-with-hover {
  &:hover {
    box-shadow: 0px 0px 12px 0px #0000001f;
  }
}